<template>
  <tbody v-if="$wait.is('response')">
    <tr>
      <td 
        v-for="(column, index) in columns" 
        :key="index"
      >
        <content-placeholder />
      </td>
    </tr>
  </tbody>
  <tbody 
    v-else 
    data-test-id="history-table-vpp"
  >
    <tr
      v-for="(item, index) in rows" 
      :key="index"
    >
      <td>
        <router-link
          :to="getLink(item)"
          class="button is-size-7-mobile button-extras"
          @click.native="track('vrm')"
        >
          {{ item.vrm }}
        </router-link>
      </td>

      <td class="is-hidden-touch">
        <router-link
          :to="getLink(item)"
          class="is-size-6 has-text-dark"
          @click.native="track('description')"
        >
          {{ truncate(item.description) }}
        </router-link>
      </td>
      <td class="is-hidden-touch">
        {{ number(item.mileage) }}
      </td>
      <td>{{ formatDate(item.createdAt) }}</td>
      <td
        v-if="depth !== 'user' && item.user" 
        class="is-hidden-touch"
      >
        {{ item.user.name }}
      </td>
      <td
        v-if="depth === 'org' && item.branch" 
        class="is-hidden-touch"
      >
        {{ item.branch.name }}
      </td>
      <td>
        <div class="field is-grouped">
          <p class="control mr-3">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              title="Check vehicle"
              v-if="hasAutoCheck"
              class="button is-size-7-mobile is-soft is-outlined"
              @click="buyCheck(item, index)"
              data-test-id="vpp-table-autocheck"
            >
              <span class="icon">
                <i class="fal fa-check" />
              </span>
            </button>
          </p>
          <p class="control">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              class="button is-size-7-mobile is-soft is-outlined"
              title="Calculate value"
              @click="value(item, index)"
              data-test-id="vpp-table-companion"
            >
              <span class="icon">
                <i class="fal fa-pound-sign" />
              </span>
            </button>
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import * as autocheck from 'modules/autocheck/services'
import * as companion from 'modules/companion/services'
import truncate from 'truncate'
import { formatDate } from '@/utils/date.utils.ts'
import { formatMoney, formatNumber } from 'accounting'
import { mapGetters } from 'vuex'
export default {
  name: 'AccountHistoryVpp',
  components: {
    contentPlaceholder: () => import('core/components/ContentPlaceholder')
  },
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      default: () => false
    },
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser', 'hasAutoCheck'])
  },
  methods: {
    formatDate,
    track(source) {
      this.$mxp.track(`${this.depth}_history_vpp_${source}_view_vpp`)
    },
    getLink(item) {
      return { name: 'vehicle-profile', params: { vrm: item.vrm } }
    },
    truncate(value) {
      return truncate(value, 45)
    },
    async buyCheck(item, index) {
      this.$mxp.track(`${this.depth}_history_vpp_open_autocheck_modal`)
      this.$wait.start(`loading-${index}`)
      try {
        const data = await autocheck.precheck({
          value: item.vrm,
          type: 'vrm'
        })
        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: data.vrm,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        this.$mxp.track(`${this.depth}_history_vpp_open_autocheck_modal_failed`)
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end(`loading-${index}`)
      }
    },
    async value({ vrm }, index) {
      if (!this.isCompanionUser) {
        this.$mxp.track(`${this.depth}_history_vpp_companion_upsell`)
        this.toggleUpsell()
      } else {
        this.$mxp.track(`${this.depth}_history_vpp_companion_request`)
        this.$wait.start(`loading-${index}`)
        await companion.value({ vrm })
        this.$wait.end(`loading-${index}`)
      }
    },
    toggleUpsell() {
      return this.$modal.open('companion/UpsellCompanion')
    },
    pounds(value) {
      return formatMoney(value, '£', 0)
    },
    percentage(value) {
      return `${formatNumber(value, 2)}%`
    },
    number(value) {
      return `${formatNumber(value)}`
    }
  }
}
</script>
